import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUOS(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/patrimoine/uos', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },   
    fetchAssets(ctx, payload) {
        return new Promise((resolve, reject) => {
          axios
            .get('/apps/patrimoine/assets', { params: payload })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },   
      addAsset(ctx, payload) {
        return new Promise((resolve, reject) => {
          axios
            .post('/apps/patrimoine/assets', payload )
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },            
  },
}