export default [

  {
    path: '/dashboard/moa',
    name: 'dashboard-moa',
    meta: {
      resource: 'dashboard',
      action: 'read',
    },    
    component: () => import('@/views/dashboard/moa/Moa.vue'),
  },  
  {
    path: '/dashboard/inspecteur',
    name: 'dashboard-inspecteur',
    component: () => import('@/views/dashboard/inspecteur/Inspecteur.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read',
    },
  },    
  {
    path: '/dashboard/mainteneur',
    name: 'dashboard-mainteneur',
    component: () => import('@/views/dashboard/mainteneur/Mainteneur.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read',
    },
  },   
]
