import mock from '@/@fake-db/mock'
// eslint-disable-next-line object-curly-newline
import { paginateArray, sortCompare, randomDate, getRandomInt } from '@/@fake-db/utils'

const nextDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
const nextWeek = new Date(nextDay.getTime() + 7 * 24 * 60 * 60 * 1000)

/* eslint-disable global-require */
const data = {   
  ouvrages: [
    {
      id: 1,
      name: 'GU133',
      slug: 'vic-tsing-wireless-mouse-1',
      plateforme: {text:'Roissy',value:'Roissy'},
      type: {text:'Pont',value:'Pont'},
      created: '1952',
      iqoa: '2',
      image: require('@/assets/images/pages/patrimoine/pont1.jpeg'),
      localisation:'[49.015, 2.5431]',
      latitude:49.015,
      longitude:2.5431
    },
    {
        id: 2,
        name: 'RM027',
        slug: 'vic-tsing-wireless-mouse-1',
        plateforme: {text:'Roissy',value:'Roissy'},
        type: {text:'Viaduc',value:'Viaduc'},
        created: '1943',
        iqoa: 1,
        image: require('@/assets/images/pages/patrimoine/pont2.jpeg'),
        localisation:'[49.0275, 2.5367]',
        latitude:49.0275,
        longitude:2.5367       
    },
    {
      id: 3,
      name: 'AL963C',
      slug: 'vic-tsing-wireless-mouse-1',
      plateforme: {text:'Roissy',value:'Roissy'},
      type: {text:'Viaduc',value:'Viaduc'},
      created: '2012',
      iqoa: '2E',
      image: require('@/assets/images/pages/patrimoine/pont3.jpeg'),
      localisation:'[49.0289, 2.5798]',
      latitude:49.0289,
      longitude:2.5798   
    },
    {
        id: 4,
        name: 'PT561',
        slug: 'vic-tsing-wireless-mouse-1',
        plateforme: {text:'Roissy',value:'Roissy'},
        type: {text:'Tunnel',value:'Tunnel'},
        created: '1952',
        iqoa: '1',
        image: require('@/assets/images/pages/patrimoine/pont4.jpeg'),
        localisation:'[48.9974, 2.5204]',
        latitude:48.9974,
        longitude:2.5204   
    },
    {
        id: 5,
        name: 'Pont 54',
        slug: 'vic-tsing-wireless-mouse-1',
        plateforme: {text:'Roissy',value:'Roissy'},
        type: {text:'Tunnel',value:'Tunnel'},
        created: '1912',
        iqoa: '3U',
        image: require('@/assets/images/pages/patrimoine/pont5.jpg'),
        localisation:'[49.0263, 2.4995]',
        latitude:49.0263,
        longitude:2.4995   
    },      
    {
        id: 6,
        name: 'GH784',
        slug: 'vic-tsing-wireless-mouse-1',
        plateforme: {text:'Bourget',value:'Bourget'},
        type: {text:'Pont',value:'Pont'},
        iqoa: '2',
        created: '1989',
        image: require('@/assets/images/pages/patrimoine/pont6.jpeg'),
        localisation:'[48.9746, 2.4601]',
        latitude:48.9746,
        longitude:2.4601   
    },
    {
        id: 7,
        name: 'GU156',
        slug: 'vic-tsing-wireless-mouse-1',
        plateforme: {text:'Bourget',value:'Bourget'},
        type: {text:'Viaduc',value:'Viaduc'},
        created: '1995',
        iqoa: '1',
        image: require('@/assets/images/pages/patrimoine/pont7.jpeg'),
        localisation:'[48.957, 2.4399]',
        latitude:48.957,
        longitude:2.4399   
    },
    {
        id: 8,
        name: 'QS421',
        slug: 'vic-tsing-wireless-mouse-1',
        plateforme: {text:'Bourget',value:'Bourget'},
        type: {text:'Pont',value:'Pont'},
        created: '2003',
        iqoa: '1',
        image: require('@/assets/images/pages/patrimoine/pont8.jpeg'),
        localisation:'[48.96, 2.43]',
        latitude:48.96,
        longitude:2.43   
    },
    {
        id: 9,
        name: 'GU148',
        slug: 'vic-tsing-wireless-mouse-1',
        plateforme: {text:'Bourget',value:'Bourget'},
        type: {text:'Viaduc',value:'Viaduc'},
        created: '1935',
        iqoa: '2',
        image: require('@/assets/images/pages/patrimoine/pont9.jpeg'),
        localisation:'[48.969, 2.4189]',
        latitude:48.969,
        longitude:2.4189   
    },                  
    {
      id: 10,
      name: 'GU133',
      slug: 'vic-tsing-wireless-mouse-1',
      plateforme: {text:'Roissy',value:'Roissy'},
      type: {text:'Pont',value:'Pont'},
      created: '1952',
      iqoa: '2',
      image: require('@/assets/images/pages/patrimoine/pont1.jpeg'),
      localisation:'[a]',
      latitude:49.015,
      longitude:2.5431
    },
    {
        id: 11,
        name: 'RM027',
        slug: 'vic-tsing-wireless-mouse-1',
        plateforme: {text:'Roissy',value:'Roissy'},
        type: {text:'Viaduc',value:'Viaduc'},
        created: '1943',
        iqoa: 1,
        image: require('@/assets/images/pages/patrimoine/pont2.jpeg'),
        localisation:'[z]',
        latitude:49.0275,
        longitude:2.5367       
    },
    {
      id: 12,
      name: 'AL963C',
      slug: 'vic-tsing-wireless-mouse-1',
      plateforme: {text:'Roissy',value:'Roissy'},
      type: {text:'Viaduc',value:'Viaduc'},
      created: '2012',
      iqoa: '2E',
      image: require('@/assets/images/pages/patrimoine/pont3.jpeg'),
      localisation:'[f]',
      latitude:49.0289,
      longitude:2.5798   
    },
    {
        id: 13,
        name: 'PT561',
        slug: 'vic-tsing-wireless-mouse-1',
        plateforme: {text:'Roissy',value:'Roissy'},
        type: {text:'Tunnel',value:'Tunnel'},
        created: '1952',
        iqoa: '1',
        image: require('@/assets/images/pages/patrimoine/pont4.jpeg'),
        localisation:'[r]',
        latitude:48.9974,
        longitude:2.5204   
    },
    {
        id: 14,
        name: 'Pont 54',
        slug: 'vic-tsing-wireless-mouse-1',
        plateforme: {text:'Roissy',value:'Roissy'},
        type: {text:'Tunnel',value:'Tunnel'},
        created: '1912',
        iqoa: '3U',
        image: require('@/assets/images/pages/patrimoine/pont5.jpg'),
        localisation:'[e]',
        latitude:49.0263,
        longitude:2.4995   
    },      
    {
        id: 15,
        name: 'GH784',
        slug: 'vic-tsing-wireless-mouse-1',
        plateforme: {text:'Bourget',value:'Bourget'},
        type: {text:'Pont',value:'Pont'},
        iqoa: '2',
        created: '1989',
        image: require('@/assets/images/pages/patrimoine/pont6.jpeg'),
        localisation:'[t]',
        latitude:48.9746,
        longitude:2.4601   
    },
    {
        id: 16,
        name: 'GU156',
        slug: 'vic-tsing-wireless-mouse-1',
        plateforme: {text:'Bourget',value:'Bourget'},
        type: {text:'Viaduc',value:'Viaduc'},
        created: '1995',
        iqoa: '1',
        image: require('@/assets/images/pages/patrimoine/pont7.jpeg'),
        localisation:'[y]',
        latitude:48.957,
        longitude:2.4399   
    },
    {
        id: 17,
        name: 'QS421',
        slug: 'vic-tsing-wireless-mouse-1',
        plateforme: {text:'Bourget',value:'Bourget'},
        type: {text:'Pont',value:'Pont'},
        created: '2003',
        iqoa: '1',
        image: require('@/assets/images/pages/patrimoine/pont8.jpeg'),
        localisation:'[u]',
        latitude:48.96,
        longitude:2.43   
    },
    {
        id: 18,
        name: 'GU148',
        slug: 'vic-tsing-wireless-mouse-1',
        plateforme: {text:'Bourget',value:'Bourget'},
        type: {text:'Viaduc',value:'Viaduc'},
        created: '1935',
        iqoa: '2',
        image: require('@/assets/images/pages/patrimoine/pont9.jpeg'),
        localisation:'[k]',
        latitude:48.969,
        longitude:2.4189   
    },
  ],
  documnts: [
    {
      id: 1,
      name: 'Rapport inspection 01/03/2020',
      slug: 'vic-tsing-wireless-mouse-1',
      type: 'Rapport',
      format: 'pdf',
      image: require('@/assets/images/pages/patrimoine/pont1.jpeg'),
    },
    {
      id: 2,
      name: 'Photo desordre C126',
      slug: 'vic-tsing-wireless-mouse-1',
      type: 'Photo',
      format: 'jpeg',
      image: require('@/assets/images/pages/patrimoine/pont1.jpeg'),
    },
    {
      id: 4,
      name: 'Relevé graphique appui nord BC759',
      slug: 'vic-tsing-wireless-mouse-1',
      type: 'Relevé',
      format: 'cad',
      image: require('@/assets/images/pages/patrimoine/pont1.jpeg'),
    },
    {
      id: 5,
      name: 'Plan de coupe longitudinal',
      slug: 'vic-tsing-wireless-mouse-1',
      type: 'Plan',
      format: 'jpeg',
      image: require('@/assets/images/pages/patrimoine/pont1.jpeg'),
    },  
    {
      id: 3,
      name: 'Fiche inspection 01/03/2020',
      slug: 'vic-tsing-wireless-mouse-1',
      type: 'Fiche',
      format: 'pdf',
      image: require('@/assets/images/pages/patrimoine/pont1.jpeg'),
    },
    {
        id: 6,
        name: 'Fiche inspection 01/03/2006',
        slug: 'vic-tsing-wireless-mouse-1',
        type: 'Fiche',
        format: 'pdf',
        image: require('@/assets/images/pages/patrimoine/pont1.jpeg'),
    },      
    {
        id: 7,
        name: 'Devis réparation fissure ',
        slug: 'vic-tsing-wireless-mouse-1',
        type: 'Devis',
        format: 'pdf',
        image: require('@/assets/images/pages/patrimoine/pont6.jpeg'),
    },
  ], 
  userWishlist: [
    { id: 1, productId: 26 },
    { id: 2, productId: 23 },
    { id: 3, productId: 11 },
    { id: 4, productId: 10 },
    { id: 5, productId: 5 },
    { id: 6, productId: 8 },
    { id: 7, productId: 9 },
    { id: 8, productId: 13 },
    { id: 9, productId: 17 },
  ],
  userCart: [
    { id: 1, productId: 27, qty: 1 },
    { id: 2, productId: 21, qty: 1 },
    { id: 3, productId: 26, qty: 1 },
    { id: 4, productId: 25, qty: 1 },
    { id: 5, productId: 23, qty: 1 },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return products
// ------------------------------------------------
mock.onGet('/apps/patrimoine/ouvrages').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', iqoa='', categorie='',sortBy='', perPage = 9, page = 1 } = config.params

  const queryLowered = q.toLowerCase()

  let filteredData = data.ouvrages.filter(ouvrage => ouvrage.name.toLowerCase().includes(queryLowered))

  if (iqoa != '')  {
    filteredData = filteredData.filter(ouvrage => ouvrage.iqoa == iqoa)
  } 

  if (categorie != '')  {
    filteredData = filteredData.filter(ouvrage => ouvrage.type.text == categorie)
  } 

  let sortDesc = false
  const sortByKey = (() => {
    if (sortBy === 'price-desc') {
      sortDesc = true
      return 'price'
    }
    if (sortBy === 'price-asc') {
      return 'price'
    }
    sortDesc = true
    return 'id'
  })()

  const sortedData = filteredData.sort(sortCompare(sortByKey))
  if (sortDesc) sortedData.reverse()

  const paginatedData = JSON.parse(JSON.stringify(paginateArray(sortedData, perPage, page)))

  paginatedData.forEach(product => {
    /* eslint-disable no-param-reassign */
    product.isInWishlist = data.userWishlist.findIndex(p => p.productId === product.id) > -1
    product.isInCart = data.userCart.findIndex(p => p.productId === product.id) > -1
    /* eslint-enable */
  })
 
  return [
    200,
    {
      products: paginatedData,
      total: filteredData.length,
      userWishlist: data.userWishlist,
      userCart: data.userCart,
    },
  ]
})

// ------------------------------------------------
// GET: Return Single User
// ------------------------------------------------
mock.onGet(/\/apps\/patrimoine\/ouvrages\/\d+/).reply(config => {
  // Get event id from URL
  let ouvrageId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  ouvrageId = Number(ouvrageId)

  const ouvrageIndex = data.ouvrages.findIndex(e => e.id === ouvrageId)
  const ouvrage = data.ouvrages[ouvrageIndex]

  if (ouvrage) return [200, ouvrage]
  return [404]
})

// ------------------------------------------------
// POST: Add new user
// ------------------------------------------------
mock.onPost('/apps/patrimoine/ouvrages').reply(config => {

  // Get event from post data
  let ouvrage = JSON.parse(config.data)

  ouvrage.id = data.ouvrages.length + 1
 
  data.ouvrages.push(ouvrage)
  return [201, { ouvrage }]
})


