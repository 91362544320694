export default [

    // *===============================================---*
    // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
    // *===============================================---*
    {
      path: '/collaboration/email',
      name: 'collaboration-email',
      component: () => import('@/views/collaboration/email/Email.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'email-application',
        resource: 'social',
        action: 'read',      
      },
    },
    {
      path: '/collaboration/email/:folder',
      name: 'collaboration-email-folder',
      component: () => import('@/views/collaboration/email/Email.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'email-application',
        navActiveLink: 'collaboration-email',
        resource: 'social',
        action: 'read',          
      },
      beforeEnter(to, _, next) {
        if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
        else next({ name: 'error-404' })
      },
    },
    {
      path: '/collaboration/email/label/:label',
      name: 'collaboration-email-label',
      component: () => import('@/views/collaboration/email/Email.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'email-application',
        navActiveLink: 'collaboration-email',
        resource: 'social',
        action: 'read',          
      },
      beforeEnter(to, _, next) {
        if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
        else next({ name: 'error-404' })
      },
    },
  
    // *===============================================---*
    // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
    // *===============================================---*
    {
      path: '/collaboration/todo',
      name: 'collaboration-todo',
      component: () => import('@/views/collaboration/todo/Todo.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'todo-application',
        resource: 'social',
        action: 'read',          
      },
    },
    {
      path: '/collaboration/todo/:filter',
      name: 'collaboration-todo-filter',
      component: () => import('@/views/collaboration/todo/Todo.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'todo-application',
        navActiveLink: 'collaboration-todo',
        resource: 'social',
        action: 'read',          
      },
      beforeEnter(to, _, next) {
        if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
        else next({ name: 'error-404' })
      },
    },
    {
      path: '/collaboration/todo/tag/:tag',
      name: 'collaboration-todo-tag',
      component: () => import('@/views/collaboration/todo/Todo.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'todo-application',
        navActiveLink: 'collaboration-todo',
        resource: 'social',
        action: 'read',          
      },
      beforeEnter(to, _, next) {
        if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
        else next({ name: 'error-404' })
      },
    },
  
  
    // *===============================================---*
    // *--------- USER ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/collaboration/users/list',
      name: 'collaboration-users-list',
      component: () => import('@/views/collaboration/user/users-list/UsersList.vue'),
      meta: {
        resource: 'social',
        action: 'read',        
      },
    },
    {
      path: '/collaboration/users/view/:id',
      name: 'collaboration-users-view',
      component: () => import('@/views/collaboration/user/users-view/UsersView.vue'),
      meta: {
        resource: 'social',
        action: 'read',        
      },    
    },
    {
      path: '/collaboration/users/edit/:id',
      name: 'collaboration-users-edit',
      component: () => import('@/views/collaboration/user/users-edit/UsersEdit.vue'),
      meta: {
        resource: 'social',
        action: 'read',        
      },    
    },

  ]
  