import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  desordres: [
    {
      id: 1,
      composants: 'GU156 - A142X - Corniche',
      type: 'Eclats',
      libelle:'DES211 - Eclat béton',
      description:'2 amorces d’éclats béton côté Sud élément 17',
      iqoa:"1",
      status:"Terminé",
      suiteadonner:"Réparation demandée",
      author:"Veritas",
      created: '30/01/2018',
      updated: '30/06/2018',
      inspection: 'GU156 - VE du 25/01/2018',
      maintenance:'MAIN154',
      documents: [require('@/assets/images/pages/incident/image4.jpeg')],
      historique: ['30/01/2018 - Véritas - Création du désordre','15/02/2018 - ADP - Création action de maintenance associée : MAIN154', '30/06/2018 - PrestaX - fin action de maintenance MAIN154', '30/06/2018 - MOA - Validation fin désordre'],
    },
    {
      id: 2,
      composants: 'GU156 - A142X - Corniche',
      type: 'Fissure',
      libelle:'DES245 - Fissures ouvertures',
      description:'Quelques fissures verticales d’ouverture < 0,4 mm',
      iqoa:"2",
      status:"En cours",
      suiteadonner:"Réparation demandée",
      author:"Veritas",
      created: '30/11/2021',
      updated: '25/12/2021',
      inspection: 'GU156 - ID du 25/11/2021',
      maintenance:'MAIN173',
      documents: [require('@/assets/images/pages/incident/image3.jpeg')],
      historique: ['30/11/2021 - Véritas - Création du désordre','25/12/2021 - ADP - Création action de maintenance associée : MAIN173'],   
    },
    {
      id: 3,
      composants: 'GU156 - A143V - Chaussée et revêtement du tablier',
      type: 'Alteration',
      libelle:'DES248 - Alteration revêtement',
      description:'Dégradation importante côté Ouest ',
      iqoa:"2",
      status:"En cours",
      suiteadonner:"Réparation demandée",
      author:"Veritas",
      created: '30/11/2021',
      updated: '25/12/2021',
      inspection: 'GU156 - ID du 25/11/2021',
      maintenance:'MAIN175',
      documents: [require('@/assets/images/pages/incident/image1.jpeg')],
      historique: ['30/11/2021 - Véritas - Création du désordre','25/12/2021 - ADP - Création action de maintenance associée : MAIN175'],   
    },
    {
      id: 4,
      composants: 'GU156 - A143V - Chaussée et revêtement du tablier',
      type: 'Alteration',
      libelle:'DES249 - Alteration revêtement',
      description:'Début de dégradation côté Nord Est  ',
      iqoa:"1",
      status:"En cours",
      suiteadonner:"Laissez en l'état",
      author:"Veritas",
      created: '30/11/2021',
      updated: '30/11/2021',
      inspection: 'GU156 - ID du 25/11/2021',
      maintenance:'',
      documents: [require('@/assets/images/pages/incident/image1.jpeg')],
      historique: ['30/11/2021 - Véritas - Création du désordre'],   
    },
    {
      id: 5,
      composants: 'GU156 - C1M59 - Bordure',
      type: 'Defaut',
      libelle:'DES253 - Bordure cassée',
      description:'bordure cassée sur le TPC côté Ouest',
      iqoa:"1",
      status:"En cours",
      suiteadonner:"Réparation demandée",
      author:"Veritas",
      created: '30/11/2021',
      updated: '25/12/2021',
      inspection: 'GU156 - ID du 25/11/2021',
      maintenance:'MAIN179',
      documents: [require('@/assets/images/pages/incident/image3.jpeg')],
      historique: ['30/11/2021 - Véritas - Création du désordre','25/12/2021 - ADP - Création action de maintenance associée : MAIN179'],   
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/apps/desordre/desordres').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
    perPage = 10,
    page = 1,
    sortBy = 'id',
    sortDesc = false,
    status = null,
  } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.desordres.filter(
    desordre =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (desordre.libelle.toLowerCase().includes(queryLowered)) &&
      desordre.status === (status || desordre.status),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      desordres: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// POST: Add new user
// ------------------------------------------------
mock.onPost('/apps/desordre/desordres').reply(config => {
  // Get event from post data
  let { desordre } = JSON.parse(config.data)

  desordre.id = Math.random() * 1000

  data.desordres.push(desordre)

  return [201, { desordre }]

})

// ------------------------------------------------
// POST: Add new user
// ------------------------------------------------
mock.onPatch('/apps/desordre/desordres').reply(config => {
  // Get event from post data
  let { desordre } = JSON.parse(config.data)

  let index = data.desordres.findIndex(x => x.id === desordre.id)
 
  data.desordres[index] = desordre

  return [201, { desordre }]

})

// ------------------------------------------------
// GET: Return Single User
// ------------------------------------------------
mock.onGet(/\/apps\/desordre\/desordres\/\d+/).reply(config => {
  // Get event id from URL
  let desordreId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  desordreId = Number(desordreId)

  const desordreIndex = data.desordres.findIndex(e => e.id === desordreId)
  const desordre = data.desordres[desordreIndex]

  if (desordre) return [200, desordre]
  return [404]
})
