import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  typeinspections: [
    {
      id: 1,
      libelle: 'Inspection détaillée',
      raccourci: 'ID',
      frequence: '3 ans',
      formulaire:'standard',
      releve:'oui'
    },
    {
      id: 2,
      libelle: 'Inspection détaillée Périodique',
      raccourci: 'IDP',
      frequence: '2 ans',
      formulaire:'Fiche AGF',
      releve:'oui'
    },
    {
      id: 3,
      libelle: 'Visite d\évaluation',
      raccourci: 'VE',
      frequence: '5 ans',
      formulaire:'standard',
      releve:'non'
    },
    {
      id: 4,
      libelle: 'Controle Annuel',
      raccourci: 'CA',
      frequence: '1 an',
      formulaire:'standard',
      releve:'oui'
    },            
  ],
  inspections: [
    {
      id: 1,
      type: 'ID',
      ouvragelst:'GU148',
      status:"en cours",
      startdate: '30/01/2022',
      start_date:'2022-01-30',
      end_date:'2022-02-05',
      enddate: '05/02/2022',
      budget: '15000',
      reelstart:'',
      reelbudget:'',
      duration: '4',
      inspecteur: 'Veritas',
      composants: 'Tous',
      documents: ['Fiche inspection'],
      trame: 'Rapport standard',
      contraintes:[{label:'Balisage de la route',tiers:'DREAL',delai:'60',resp:'MOA'},{label:'Information',tiers:'SNCF',delai:'90',resp:'Inspecteur'}],
      taches:[{responsable:"Veritas/Michel Durand",deadline:"05/02/2022",tache:"Prévenance DREAL pour balisage route", status:"A faire"},{responsable:"ADP/Gérard Dupont",deadline:"05/01/2022",tache:"Prévenance SNCF - Information inspection", status:"OK"}],
      roles: [{role:'MOA',societe:'ADP',personne:'Gérard Dupont'},{role:'Inspecteur',societe:'Veritas',personne:'Michel Durand'},{role:'Valideur',societe:'ADP',personne:'Gérard Dupont'}],
    },
    {
      id: 2,
      type: 'IDP',
      ouvragelst:'QS421',
      status:"terminé",
      startdate: '10/01/2022',
      enddate: '14/01/2022',
      start_date:'2022-01-30',
      end_date:'2022-02-05',   
      reelstart:'2022-01-12',
      reelbudget:'17850',   
      budget: '15000',
      duration: '4',
      inspecteur: 'Veritas',
      composants: 'Tous',
      documents: ['Rapport d\'inspection', 'Fiche inspection'],
      trame: 'Rapport standard',
      contraintes:[{label:'Balisage de la route',tiers:'DREAL',delai:'60',resp:'MOA'},{label:'Information',tiers:'SNCF',delai:'90',resp:'Inspecteur'}],
      taches:[{responsable:"Veritas/Michel Durand",deadline:"05/02/2022",tache:"Prévenance DREAL pour balisage route", status:"A faire"},{responsable:"ADP/Gérard Dupont",deadline:"05/01/2022",tache:"Prévenance SNCF - Information inspection", status:"OK"}],
      roles: [{role:'MOA',societe:'ADP',personne:'Gérard Dupont'},{role:'Inspecteur',societe:'Veritas',personne:'Michel Durand'},{role:'Valideur',societe:'ADP',personne:'Gérard Dupont'}],
    },
    {
      id: 3,
      type: 'CA',
      ouvragelst:'GU156',
      status:"en cours",
      startdate: '10/01/2020',
      enddate: '14/01/2020',
      start_date:'2022-01-10',
      end_date:'2022-01-14',   
      reelstart:'',
      reelbudget:'',   
      budget: '15000',
      duration: '4',
      inspecteur: 'Veritas',
      composants: 'Tous',
      documents: ['Fiche inspection'],
      trame: 'Rapport standard',
      contraintes:[{label:'Balisage de la route',tiers:'DREAL',delai:'60',resp:'MOA'},{label:'Information',tiers:'SNCF',delai:'90',resp:'Inspecteur'}],
      taches:[{responsable:"Veritas/Michel Durand",deadline:"05/02/2022",tache:"Prévenance DREAL pour balisage route", status:"A faire"},{responsable:"ADP/Gérard Dupont",deadline:"05/01/2022",tache:"Prévenance SNCF - Information inspection", status:"OK"}], 
      roles: [{role:'MOA',societe:'ADP',personne:'Gérard Dupont'},{role:'Inspecteur',societe:'Veritas',personne:'Michel Durand'},{role:'Valideur',societe:'ADP',personne:'Gérard Dupont'}],
    },
    {
      id: 14,
      type: 'VE',
      ouvragelst:'GU148, QS421',
      status:"retard",
      startdate: '10/01/2022',
      enddate: '14/01/2022',
      start_date:'2022-02-10',
      end_date:'2022-02-14',    
      reelstart:'',
      reelbudget:'',   
      budget: '15000',
      duration: '4',
      inspecteur: 'Veritas',
      composants: 'Tous',
      documents: ['Fiche inspection'],
      trame: 'Rapport standard',
      contraintes:[{label:'Balisage de la route',tiers:'DREAL',delai:'60',resp:'MOA'},{label:'Information',tiers:'SNCF',delai:'90',resp:'Inspecteur'}],
      taches:[{responsable:"Veritas/Michel Durand",deadline:"05/02/2022",tache:"Prévenance DREAL pour balisage route", status:"A faire"},{responsable:"ADP/Gérard Dupont",deadline:"05/01/2022",tache:"Prévenance SNCF - Information inspection", status:"OK"}],
      roles: [{role:'MOA',societe:'ADP',personne:'Gérard Dupont'},{role:'Inspecteur',societe:'Veritas',personne:'Michel Durand'},{role:'Valideur',societe:'ADP',personne:'Gérard Dupont'}],
    },
    {
      id: 5,
      type: 'ID',
      ouvragelst:'GH784',
      status:"retard",
      startdate: '10/01/2022',
      enddate: '14/01/2022',
      start_date:'2022-01-10',
      end_date:'2022-01-14',  
      reelstart:'',
      reelbudget:'',     
      budget: '15000',
      duration: '4',
      inspecteur: 'Veritas',
      composants: 'Tous',
      documents: ['Fiche inspection'],
      trame: 'Rapport standard',
      contraintes:[{label:'Balisage de la route',tiers:'DREAL',delai:'60',resp:'MOA'},{label:'Information',tiers:'SNCF',delai:'90',resp:'Inspecteur'}],
      taches:[{responsable:"Veritas/Michel Durand",deadline:"05/02/2022",tache:"Prévenance DREAL pour balisage route", status:"A faire"},{responsable:"ADP/Gérard Dupont",deadline:"05/01/2022",tache:"Prévenance SNCF - Information inspection", status:"OK"}],
      roles: [{role:'MOA',societe:'ADP',personne:'Gérard Dupont'},{role:'Inspecteur',societe:'Veritas',personne:'Michel Durand'},{role:'Valideur',societe:'ADP',personne:'Gérard Dupont'}],
    },
    {
      id: 6,
      type: 'CA',
      ouvragelst:'Pont 54',
      status:"rapport",
      startdate: '23/11/2021',
      enddate: '27/11/2021',
      start_date:'2021-11-23',
      end_date:'2021-11-27',       
      reelstart:'2022-11-30',
      reelbudget:'12500',
      budget: '15000',
      duration: '4',
      inspecteur: 'Veritas',
      composants: 'Tous',
      documents: ['Fiche inspection'],
      trame: 'Rapport standard',
      contraintes:[{label:'Balisage de la route',tiers:'DREAL',delai:'60',resp:'MOA'},{label:'Information',tiers:'SNCF',delai:'90',resp:'Inspecteur'}],
      taches:[{responsable:"Veritas/Michel Durand",deadline:"05/02/2022",tache:"Prévenance DREAL pour balisage route", status:"A faire"},{responsable:"ADP/Gérard Dupont",deadline:"05/01/2022",tache:"Prévenance SNCF - Information inspection", status:"OK"}],
      roles: [{role:'MOA',societe:'ADP',personne:'Gérard Dupont'},{role:'Inspecteur',societe:'Veritas',personne:'Michel Durand'},{role:'Valideur',societe:'ADP',personne:'Gérard Dupont'}],
    },
    {
      id: 7,
      type: 'IDP',
      ouvragelst:'PT561',
      status:"planifié",
      startdate: '10/07/2022',
      enddate: '14/07/2022',
      start_date:'2022-07-10',
      end_date:'2022-07-14',  
      reelstart:'',
      reelbudget:'',     
      budget: '15000',
      duration: '4',
      inspecteur: 'Veritas',
      composants: 'Tous',
      documents: ['Fiche inspection'],
      trame: 'Rapport standard',
      contraintes:[{label:'Balisage de la route',tiers:'DREAL',delai:'60',resp:'MOA'},{label:'Information',tiers:'SNCF',delai:'90',resp:'Inspecteur'}],
      taches:[{responsable:"Veritas/Michel Durand",deadline:"05/02/2022",tache:"Prévenance DREAL pour balisage route", status:"A faire"},{responsable:"ADP/Gérard Dupont",deadline:"05/01/2022",tache:"Prévenance SNCF - Information inspection", status:"OK"}],
      roles: [{role:'MOA',societe:'ADP',personne:'Gérard Dupont'},{role:'Inspecteur',societe:'Veritas',personne:'Michel Durand'},{role:'Valideur',societe:'ADP',personne:'Gérard Dupont'}],
    },
    {
      id: 8,
      type: 'CA',
      ouvragelst:'AL963C, RM027, GU133',
      status:"rapport",
      startdate: '10/01/2022',
      enddate: '14/01/2022',
      start_date:'2022-03-10',
      end_date:'2022-03-14',  
      reelstart:'2022-01-10',
      reelbudget:'13248',     
      budget: '15000',
      duration: '4',
      inspecteur: 'Veritas',
      composants: 'Tous',
      documents: ['Fiche inspection'],
      trame: 'Rapport standard',
      contraintes:[{label:'Balisage de la route',tiers:'DREAL',delai:'60',resp:'MOA'},{label:'Information',tiers:'SNCF',delai:'90',resp:'Inspecteur'}],
      taches:[{responsable:"Veritas/Michel Durand",deadline:"05/02/2022",tache:"Prévenance DREAL pour balisage route", status:"A faire"},{responsable:"ADP/Gérard Dupont",deadline:"05/01/2022",tache:"Prévenance SNCF - Information inspection", status:"OK"}],
      roles: [{role:'MOA',societe:'ADP',personne:'Gérard Dupont'},{role:'Inspecteur',societe:'Veritas',personne:'Michel Durand'},{role:'Valideur',societe:'ADP',personne:'Gérard Dupont'}],
    },
    {
      id: 9,
      type: 'VE',
      ouvragelst:'GU13',
      status:"terminé",
      startdate: '10/12/2021',
      enddate: '14/12/2021',
      start_date:'2022-02-10',
      end_date:'2022-02-14',   
      reelstart:'2021-12-13',
      reelbudget:'16050',   
      budget: '15000',
      duration: '4',
      inspecteur: 'Veritas',
      composants: 'Tous',
      documents: ['Rapport d\'inspection', 'Fiche inspection'],
      trame: 'Rapport standard',
      contraintes:[{label:'Balisage de la route',tiers:'DREAL',delai:'60',resp:'MOA'},{label:'Information',tiers:'SNCF',delai:'90',resp:'Inspecteur'}],
      taches:[{responsable:"Veritas/Michel Durand",deadline:"05/02/2022",tache:"Prévenance DREAL pour balisage route", status:"A faire"},{responsable:"ADP/Gérard Dupont",deadline:"05/01/2022",tache:"Prévenance SNCF - Information inspection", status:"OK"}],
      roles: [{role:'MOA',societe:'ADP',personne:'Gérard Dupont'},{role:'Inspecteur',societe:'Veritas',personne:'Michel Durand'},{role:'Valideur',societe:'ADP',personne:'Gérard Dupont'}],
    },
    {
      id: 10,
      type: 'CA',
      ouvragelst:'AL963C',
      status:"terminé",
      startdate: '10/01/2022',
      enddate: '14/01/2022',
      start_date:'2022-01-20',
      end_date:'2022-01-24', 
      reelstart:'2022-01-14',
      reelbudget:'18590',      
      budget: '15000',
      duration: '4',
      inspecteur: 'Veritas',
      composants: 'Tous',
      documents: ['Rapport d\'inspection', 'Fiche inspection'],
      trame: 'Rapport standard',
      contraintes:[{label:'Balisage de la route',tiers:'DREAL',delai:'60',resp:'MOA'},{label:'Information',tiers:'SNCF',delai:'90',resp:'Inspecteur'}],
      taches:[{responsable:"Veritas/Michel Durand",deadline:"05/02/2022",tache:"Prévenance DREAL pour balisage route", status:"A faire"},{responsable:"ADP/Gérard Dupont",deadline:"05/01/2022",tache:"Prévenance SNCF - Information inspection", status:"OK"}],
      roles: [{role:'MOA',societe:'ADP',personne:'Gérard Dupont'},{role:'Inspecteur',societe:'Veritas',personne:'Michel Durand'},{role:'Valideur',societe:'ADP',personne:'Gérard Dupont'}],
    },
    {
      id: 11,
      type: 'IDP',
      ouvragelst:'RM027',
      status:"en cours",
      startdate: '12/02/2022',
      enddate: '16/02/2022',
      start_date:'2022-02-12',
      end_date:'2022-02-16',
      reelstart:'',
      reelbudget:'',       
      budget: '15000',
      duration: '4',
      inspecteur: 'Veritas',
      composants: 'Tous',
      documents: ['Fiche inspection'],
      trame: 'Rapport standard',
      contraintes:[{label:'Balisage de la route',tiers:'DREAL',delai:'60',resp:'MOA'},{label:'Information',tiers:'SNCF',delai:'90',resp:'Inspecteur'}],
      taches:[{responsable:"Veritas/Michel Durand",deadline:"05/02/2022",tache:"Prévenance DREAL pour balisage route", status:"A faire"},{responsable:"ADP/Gérard Dupont",deadline:"05/01/2022",tache:"Prévenance SNCF - Information inspection", status:"OK"}],
      roles: [{role:'MOA',societe:'ADP',personne:'Gérard Dupont'},{role:'Inspecteur',societe:'Veritas',personne:'Michel Durand'},{role:'Valideur',societe:'ADP',personne:'Gérard Dupont'}],
    },
    {
      id: 12,
      type: 'VE',
      ouvragelst:'GU133',
      status:"planifié",
      startdate: '05/03/2022',
      enddate: '14/03/2022',
      start_date:'2022-03-05',
      end_date:'2022-03-14',   
      reelstart:'',
      reelbudget:'',    
      budget: '15000',
      duration: '4',
      inspecteur: 'Veritas',
      composants: 'Tous',
      documents: ['Fiche inspection'],
      trame: 'Rapport standard',
      contraintes:[{label:'Balisage de la route',tiers:'DREAL',delai:'60',resp:'MOA'},{label:'Information',tiers:'SNCF',delai:'90',resp:'Inspecteur'}],
      taches:[{responsable:"Veritas/Michel Durand",deadline:"05/02/2022",tache:"Prévenance DREAL pour balisage route", status:"A faire"},{responsable:"ADP/Gérard Dupont",deadline:"05/01/2022",tache:"Prévenance SNCF - Information inspection", status:"OK"}],
      roles: [{role:'MOA',societe:'ADP',personne:'Gérard Dupont'},{role:'Inspecteur',societe:'Veritas',personne:'Michel Durand'},{role:'Valideur',societe:'ADP',personne:'Gérard Dupont'}],
    },
    {
      id: 13,
      type: 'CA',
      ouvragelst:'GU148',
      status:"rapport",
      startdate: '10/09/2021',
      enddate: '14/09/2021',
      start_date:'2021-09-10',
      end_date:'2021-09-14', 
      reelstart:'2021-10-15',
      reelbudget:'13260',      
      budget: '15000',
      duration: '4', 
      inspecteur: 'Veritas',
      composants: 'Tous',
      documents: ['Fiche inspection'],
      trame: 'Rapport standard',
      contraintes:[{label:'Balisage de la route',tiers:'DREAL',delai:'60',resp:'MOA'},{label:'Information',tiers:'SNCF',delai:'90',resp:'Inspecteur'}],
      taches:[{responsable:"Veritas/Michel Durand",deadline:"05/02/2022",tache:"Prévenance DREAL pour balisage route", status:"A faire"},{responsable:"ADP/Gérard Dupont",deadline:"05/01/2022",tache:"Prévenance SNCF - Information inspection", status:"OK"}],
      roles: [{role:'MOA',societe:'ADP',personne:'Gérard Dupont'},{role:'Inspecteur',societe:'Veritas',personne:'Michel Durand'},{role:'Valideur',societe:'ADP',personne:'Gérard Dupont'}],
    },
    {
      id: 4,
      type: 'ID',
      ouvragelst:'GU156',
      status:"planifié",
      startdate: '10/07/2022',
      enddate: '14/07/2022',
      start_date:'2022-07-10',
      end_date:'2022-07-14', 
      reelstart:'',
      reelbudget:'',      
      budget: '15000',
      duration: '4',
      inspecteur: 'Veritas',
      composants: 'Tous',
      documents: ['Fiche inspection'],
      trame: 'Rapport standard',
      contraintes:[{label:'Balisage de la route',tiers:'DREAL',delai:'60',resp:'MOA'},{label:'Information',tiers:'SNCF',delai:'90',resp:'Inspecteur'}],
      taches:[{responsable:"Veritas/Michel Durand",deadline:"05/02/2022",tache:"Prévenance DREAL pour balisage route", status:"A faire"},{responsable:"ADP/Gérard Dupont",deadline:"05/01/2022",tache:"Prévenance SNCF - Information inspection", status:"OK"}],
      roles: [{role:'MOA',societe:'ADP',personne:'Gérard Dupont'},{role:'Inspecteur',societe:'Veritas',personne:'Michel Durand'},{role:'Valideur',societe:'ADP',personne:'Gérard Dupont'}],
    },
    {
      id: 15,
      type: 'IDP',
      ouvragelst:'RM027',
      status:"planifié",
      startdate: '20/04/2022',
      enddate: '28/04/2022',
      start_date:'2022-04-20',
      end_date:'2022-04-28',  
      reelstart:'',
      reelbudget:'',     
      budget: '15000',
      duration: '8',
      inspecteur: 'Veritas',
      composants: 'Tous',
      documents: ['Fiche inspection'],
      trame: 'Rapport standard',
      contraintes:[{label:'Balisage de la route',tiers:'DREAL',delai:'60',resp:'MOA'},{label:'Information',tiers:'SNCF',delai:'90',resp:'Inspecteur'}],
      taches:[{responsable:"Veritas/Michel Durand",deadline:"05/02/2022",tache:"Prévenance DREAL pour balisage route", status:"A faire"},{responsable:"ADP/Gérard Dupont",deadline:"05/01/2022",tache:"Prévenance SNCF - Information inspection", status:"OK"}],
      roles: [{role:'MOA',societe:'ADP',personne:'Gérard Dupont'},{role:'Inspecteur',societe:'Veritas',personne:'Michel Durand'},{role:'Valideur',societe:'ADP',personne:'Gérard Dupont'}],
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/apps/inspection/inspections').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = {},
    perPage = 10,
    page = 1,
    sortBy = 'id',
    sortDesc = true,
  } = config.params
  /* eslint-enable */

  let filteredData = data.inspections

  let typeaction = []      

  q.type.forEach(a => typeaction.push(a))
  if (typeaction.length > 0)  {
      filteredData = filteredData.filter(
        inspection => typeaction.indexOf(inspection.type) !== -1 
    )
  }

  let asset = []      

  q.assets.forEach(a => asset.push(a))
  if (asset.length > 0)  {
      filteredData = filteredData.filter(
        inspection => asset.indexOf(inspection.ouvragelst) !== -1 
    )
  }  

  let status = []      

  q.status.forEach(a => status.push(a.toLowerCase()))
  if (status.length > 0)  {
      filteredData = filteredData.filter(
      inspection => status.indexOf(inspection.status) !== -1 
    )
  }     

  var queryLowered = ''  

  if (q.queries.length > 0) {
     queryLowered = q.queries[0].toLowerCase()
     filteredData = filteredData.filter(
      inspection => (inspection.ouvragelst.toLowerCase().includes(queryLowered))
    )
  }


  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      inspections: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// POST: Add new user or update
// ------------------------------------------------
mock.onPost('/apps/inspection/inspections').reply(config => {
  // Get event from post data
  const inspection  = JSON.parse(config.data)
  if (inspection.id === '') {
    inspection.id = data.inspections.length + 1
    data.inspections.push(inspection)
  } else {

    const inspectionIndex = data.inspections.findIndex(e => e.id === inspection.id)

   data.inspections[inspectionIndex] = inspection
  }
  return [201, { inspection }]
})

// ------------------------------------------------
// GET: Return Single User
// ------------------------------------------------
mock.onGet(/\/apps\/inspection\/inspections\/\d+/).reply(config => {
  // Get event id from URL
  let inspectionId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  inspectionId = Number(inspectionId)

  const inspectionIndex = data.inspections.findIndex(e => e.id === inspectionId)
  const inspection = data.inspections[inspectionIndex]

  if (inspection) return [200, inspection]
  return [404]
})

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/apps/inspection/types').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
    perPage = 10,
    page = 1,
    sortBy = 'id',
    sortDesc = false,
  } = config.params
  /* eslint-enable */

  const sortedData = data.typeinspections.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      typeinspections: paginateArray(sortedData, perPage, page),
      total: data.typeinspections.length,
    },
  ]
})

// ------------------------------------------------
// POST: Add new user
// ------------------------------------------------
mock.onPost('/apps/inspection/types').reply(config => {
  // Get event from post data
  let { typeinspection } = JSON.parse(config.data)

  typeinspection.id = Math.random() * 1000

  data.typeinspections.push(typeinspection)

  return [201, { typeinspection }]
})

// ------------------------------------------------
// GET: Return Single User
// ------------------------------------------------
mock.onGet(/\/apps\/inspection\/types\/\d+/).reply(config => {
  // Get event id from URL
  let inspectionId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  inspectionId = Number(inspectionId)

  const inspectionIndex = data.typeinspections.findIndex(e => e.id === inspectionId)
  const inspection = data.typeinspections[inspectionIndex]

  if (inspection) return [200, inspection]
  return [404]
})