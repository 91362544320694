import mock from '@/@fake-db/mock'
// eslint-disable-next-line object-curly-newline
import { paginateArray, sortCompare, randomDate, getRandomInt } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {   
  assets: [
    {
        id: 1,
        type: 'worksite',
        name: 'Paris Charles De Gaulle - Roissy',
        parentid: 1,
        uoid:1,
        center: [2.551231384277344,49.01022605310204],
        zoomlevel:14,     
      },
      {
          id: 2,
          type: 'worksite',
          name: 'Paris - Orly',
          parentid: 2,
          uoid:1,
          center: [2.3678970336914067,48.72708142926293],
          zoomlevel:14,     
      },
      {
        id: 3,
        type: 'worksite',
        name: 'Paris - Le Bourget',
        parentid: 3,
        uoid:1,
        center: [2.438278198242188,48.95687584173926],
        zoomlevel:14,     
      },


      {
        id: 4,
        type: 'pont',
        parentid: 1,
        uoid:1,
        zoomlevel:18, 
        center:[2.5431,49.015],

        name: 'GU133',
        created: '1952',
        iqoa: '2',
        image: require('@/assets/images/pages/patrimoine/pont1.jpeg'),
      },
      {
        id: 5,
        name: 'RM027',
        type: 'pont',
        parentid: 1,
        uoid:1,
        zoomlevel:18, 
        center:[2.5367,49.0275],
        created: '1943',
        iqoa: '1',
        image: require('@/assets/images/pages/patrimoine/pont2.jpeg'),
  
    },
    {
      id: 6,
      name: 'AL963C',
      type: 'pontela',
      parentid: 1,
      uoid:1,
      zoomlevel:18, 
      center:[2.5798, 49.0289],
      created: '2012',
      iqoa: '2E',
      image: require('@/assets/images/pages/patrimoine/pont3.jpeg'),

    },


      {
          id: 7,
          name: 'PT561',
          type: 'pontela',
          parentid: 1,
          uoid:1,
          zoomlevel:18, 
          center:[2.5204,48.9974],          
          created: '1952',
          iqoa: '1',
          image: require('@/assets/images/pages/patrimoine/pont4.jpeg'),
      },
      {
          id: 8,
          name: 'Pont 54',
          type: 'pontcad',
          parentid: 1,
          uoid:1,
          zoomlevel:18, 
          center:[2.4995,49.0263], 
          created: '1912',
          iqoa: '3U',
          image: require('@/assets/images/pages/patrimoine/pont5.jpg'),
  
      },      
      {
          id: 9,
          name: 'GH784',
          type: 'pontela',
          parentid: 3,
          uoid:1,
          zoomlevel:18, 
          center:[2.4601,48.9746],  
          iqoa: '2',
          created: '1989',
          image: require('@/assets/images/pages/patrimoine/pont6.jpeg'),

      },
      {
          id: 10,
          name: 'GU156',
          type: 'pontcad',
          parentid: 3,
          uoid:1,
          zoomlevel:18, 
          center:[2.4399,48.957],  
          created: '1995',
          iqoa: '1',
          image: require('@/assets/images/pages/patrimoine/pont7.jpeg'),
  
      },
      {
          id: 11,
          name: 'QS421',
          type: 'pontcad',
          parentid: 3,
          uoid:1,
          zoomlevel:18, 
          center:[2.43,48.96],  
          created: '2003',
          iqoa: '1',
          image: require('@/assets/images/pages/patrimoine/pont8.jpeg'),
 
      },
      {
          id: 12,
          name: 'GU148',
          type: 'tablierpout',
          parentid: 3,
          uoid:1,
          zoomlevel:18, 
          center:[2.4189,48.969],
          created: '1935',
          iqoa: '2',
          image: require('@/assets/images/pages/patrimoine/pont9.jpeg'),
  
      },                  

      {
        id:13,
        name:"PO-963",
        uoid:1,
        zoomlevel:18,
        type:"pont",
        created:"2003",
        iqoa:"3",
        image:require('@/assets/images/pages/patrimoine/pont9.jpeg'),
        parentid:1,
        center:[2.5499449044522584,49.009479597333836]
    },
    {
        id:14,
        name:"PR-924",
        uoid:1,
        zoomlevel:18,
        type:"pontcad",
        created:"1986",
        iqoa:"1",
        image:require('@/assets/images/pages/patrimoine/pont7.jpeg'),
        parentid:1,
        center:[2.551531093684202,49.0064814094859]
    },
    {
        id:15,
        name:"PO-987",
        uoid:1,
        zoomlevel:18,
        type:"tablierpontpoutre",
        created:"1963",
        iqoa:"1",
        image:require('@/assets/images/pages/patrimoine/pont7.jpeg'),
        parentid:1,
        center:[2.5576462705915657,49.005673647643306]
    },  
    {
        id:16,
        name:"PT-463",
        type:"tablierpont",
        created:"2002",
        iqoa:"2E",
        zoomlevel:18,
        uoid:1,
        image:require('@/assets/images/pages/patrimoine/pont9.jpeg'),
        parentid:1,
        center:[2.531576259834414,49.01605089442333]
    },
    {
        id:17,
        name:"PU-564",
        type:"tablierpontpoutre",
        created:"1989",
        iqoa:"1",
        zoomlevel:18,
        uoid:1,
        image:require('@/assets/images/pages/patrimoine/pont8.jpeg'),
        parentid:1,
        center:[2.5691774559680725,49.0196024514768]},
    
    {
        id:18,
        name:"PG-821",
        type:"pont",
        created:"1984",
        iqoa:"2",
        zoomlevel:18,
        uoid:1,
        image:require('@/assets/images/pages/patrimoine/pont6.jpeg'),
        parentid:3,
        center:[2.4441207903342668,48.96790340328039]},

    {
        id:19,
        name:"PG-534",
        type:"tablierpont",
        created:"2010",
        iqoa:"1",
        zoomlevel:18,
        uoid:1,
        image:require('@/assets/images/pages/patrimoine/pont5.jpg'),
        parentid:3,
        center:[2.450708925048672,48.962682824080275]},
        
    {
        id:20,
        name:"PF-664",
        type:"tablierpontpoutre",
        created:"1975",
        iqoa:"3",
        zoomlevel:18,
        uoid:1,
        image:require('@/assets/images/pages/patrimoine/pont4.jpeg'),
        parentid:3,
        center:[2.429995536804199,48.94809279225774]},
    
    {
        id:21,
        name:"PO-365",
        type:"pont",
        created:"1989",
        iqoa:"1",
        zoomlevel:18,
        uoid:1,
        image:require('@/assets/images/pages/patrimoine/pont5.jpg'),
        parentid:2,
        center:[2.3683806671838012,48.728731080234496]},
     
    {
        id:22,
        name:"PF-548",
        type:"murbe",
        created:"2003",
        iqoa:"2",
        zoomlevel:18,
        uoid:1,
        image:require('@/assets/images/pages/patrimoine/pont9.jpeg'),
        parentid:2,
        center:[2.370710819203855,48.72179321313379]},
      
    {
        id:23,
        name:"PF-563",
        type:"buse",
        created:"1968",
        iqoa:"3",
        zoomlevel:18,
        uoid:1,
        image:require('@/assets/images/pages/patrimoine/pont8.jpeg'),
        parentid:2,
        center:[2.3691993692449005,48.72665397489757]},
        
    {
        id:24,
        name:"PO-451",
        type:"tablierpout",
        created:"1963",
        iqoa:"1",
        zoomlevel:18,
        uoid:1,
        image:require('@/assets/images/pages/patrimoine/pont2.jpeg'),
        parentid:2,
        center:[2.3629159325431437,48.73024662279471]},
    
    {
        id:25,
        name:"PO-555",
        type:"pontela",
        created:"2010",
        iqoa:"1",
        zoomlevel:18,
        uoid:1,
        image:require('@/assets/images/pages/patrimoine/pont4.jpeg'),
        parentid:2,
        center:[2.3635616396045314,48.7305697271039]},
        
    {
        id:26,
        name:"PO-965",
        type:"pont",
        created:"1952",
        iqoa:"2",
        zoomlevel:18,
        uoid:1,
        image:require('@/assets/images/pages/patrimoine/pont3.jpeg'),
        parentid:2,
        center:[2.3657963374823527,48.73486676966968]},
        
    





  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return products
// ------------------------------------------------
mock.onGet('/apps/patrimoine/assets').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', uoList=[], worksiteid=-1, perPage = 1000, page = 1 } = config.params

  const queryLowered = q.toLowerCase()

  let filteredData = data.assets.filter(asset => asset.name.toLowerCase().includes(queryLowered))

  let uoId = []      

  uoList.forEach(a => uoId.push(a.id))

  if (uoId.length > 0)  {
    filteredData = filteredData.filter(asset => uoId.indexOf(asset.uoid) !== -1 )
  } 

  if (worksiteid !== -1) {
    filteredData = filteredData.filter(asset => asset.parentid === worksiteid )
  }

  const paginatedData = JSON.parse(JSON.stringify(paginateArray(filteredData, perPage, page)))
  return [
    200,
    {
      assets: paginatedData,
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// GET: Return Single User
// ------------------------------------------------
mock.onGet(/\/apps\/patrimoine\/asset\/\d+/).reply(config => {
  // Get event id from URL
  let assetId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  assetId = Number(assetId)

  const assetIndex = data.assets.findIndex(e => e.id === assetId)
  const asset = data.assets[assetIndex]

  if (asset) return [200, asset]
  return [404]
})

// ------------------------------------------------
// POST: Add new user
// ------------------------------------------------
mock.onPost('/apps/patrimoine/assets').reply(config => {
  // Get event from post data

  let asset = JSON.parse(config.data)

  asset.id = data.assets.length + 1
 
  data.assets.push(asset)

  return [201, { asset }]
})


