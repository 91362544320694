import store from '@/store'
import Assettypes from '@/@fake-db/data/apps/assettype.js'

const userContext = {
    // eslint-disable-next-line no-unused-vars
    install(Vue, options) {
       
        Vue.prototype.$userContext = {uoId:1, uo:{}, worksiteFilter:-1,assetFilter:-1, queryFilter:'', uoList:[], worksiteList:[], assetList:[], filteredWorksiteList:[], filteredAssetList:[], filters:{wks:[],assets:[],types:[],queries:[],iqoas:[]}}

        Vue.prototype.$userContextChanged = function(emitChange) {
            if (emitChange) window.dispatchEvent(new CustomEvent('usercontext-changed'));  
        }

        Vue.prototype.$userContextSetUoList = function(emitChange = true,lstUo) {
            this.$userContext.uoList = lstUo
            this.$userContextChanged(emitChange)
        }

        Vue.prototype.$userContextSetAssetList = function(emitChange = true,lstAsset) {
            this.$userContext.assetList = []
            lstAsset.forEach(w => {if (w.type !== 'worksite') {this.$userContext.assetList.push(w)}}) 
            this.$userContext.filteredAssetList = this.$userContext.assetList
            this.$userContextSetWorksiteList(emitChange,lstAsset)
            this.$userContextChanged(emitChange)
        }        

        Vue.prototype.$userContextSetWorksiteList = function(emitChange = true,lstAsset) {
            this.$userContext.worksiteList = []
            lstAsset.forEach(w => {if (w.type === 'worksite') {this.$userContext.worksiteList.push(w)}}) 
            this.$userContext.filteredWorksiteList = this.$userContext.worksiteList          
            this.$userContextChanged(emitChange)
        }          

        Vue.prototype.$userContextSetFilteredAssetList = function(emitChange = true,lstFilters) {
            this.$userContext.filters = lstFilters
            this.$userContext.filteredAssetList = this.$userContext.assetList
            let tmpList = []
            if (this.$userContext.filters.wks.length > 0) {
                this.$userContext.filteredAssetList.filter(a => this.$userContext.filters.wks.includes(a.parentid)).forEach(b => tmpList[b.id] = b)
                this.$userContext.filteredAssetList = this.$userContext.filteredAssetList.filter(a => tmpList.includes(a.parentid))
                tmpList.forEach(a => this.$userContext.filteredAssetList.push(a))
            }

            if (this.$userContext.filters.assets.length > 0) {
                this.$userContext.filteredAssetList = this.$userContext.filteredAssetList.filter(a => this.$userContext.filters.assets.includes(a.parentid))
                this.$userContext.filters.assets.forEach(a => this.$userContext.filteredAssetList.push(this.$userContext.assetList.find(b => b.id === a)))
            }

            if (this.$userContext.filters.types.length > 0) {
                this.$userContext.filteredAssetList = this.$userContext.filteredAssetList.filter(a => this.$userContext.filters.types.includes(a.type))
            }

            if (this.$userContext.filters.iqoas.length > 0) {
                this.$userContext.filteredAssetList = this.$userContext.filteredAssetList.filter(a => this.$userContext.filters.iqoas.includes(a.iqoa))
            }            
            tmpList = []
            if (this.$userContext.filters.queries.length > 0) {
                this.$userContext.filters.queries.forEach(a => {
                    this.$userContext.filteredAssetList.filter(b => b.name.toLowerCase().includes(a.toLowerCase())).forEach(
                        c => tmpList[c.id] = c
                    )
                })
                this.$userContext.filteredAssetList = tmpList
                
            }

            this.$userContextChanged(emitChange)
        }   

        Vue.prototype.$userContextSetFilteredWorksiteList = function(emitChange = true,lstWk) {
            if (lstWk.length > 0) {
                this.$userContext.filteredWorksiteList = []
                lstWk.forEach(w => this.$userContext.filteredWorksiteList.push(this.$userContext.worksiteList.find(a => a.id === w))) 
            } else {
                this.$userContext.filteredWorksiteList = this.$userContext.worksiteList    
            }    
            this.$userContextChanged(emitChange)
        }           

        Vue.prototype.$userContextSetUo = function(emitChange = true, uoId) {
            this.$userContext.uoId = uoId    
            this.$userContext.uo = this.$userContext.uoList.find(l => l.id === Number(uoId))
            this.$userContextfetchAssets(false)
            this.$userContextChanged(emitChange)
        }

        

        Vue.prototype.$userContextGetUoId = function() {
            return this.$userContext.uoId    
        }        

        Vue.prototype.$userContextSetAsset = function(emitChange = true, assetId) {
            this.$userContext.assetFilter = assetId  
            this.$userContextChanged(emitChange)   
        }

        Vue.prototype.$userContextSetQuery = function(emitChange = true, query) {
            this.$userContext.queryFilter = query  
            this.$userContextChanged(emitChange)   
        }

        Vue.prototype.$userContextGetLayerVisibility = function(assetType) {
            if ((this.$userContext.filters.wks.length > 0 || this.$userContext.filters.assets.length > 0 || this.$userContext.filters.types.length > 0) && assetType === "worksite") return false 
            if ((this.$userContext.filters.wks.length === 0 && this.$userContext.filters.assets.length === 0 && this.$userContext.filters.types.length === 0) && assetType !== "worksite") return false 
            return true
        }

        Vue.prototype.$userContextfetchUo = function(emitChange = true) {
            store
            .dispatch('app-aloha/fetchUOS', {
            // q: searchQuery.value,
              q: '',
              parentid:this.$userContext.uoId
              /*perPage: perPage.value,
              page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
              status: statusFilter.value,*/
            })
            .then(response => {
              const { uos, total } = response.data
              this.$userContextSetUoList(false,uos)
              this.$userContextSetUo(emitChange, this.$userContext.uoId)
            })
            .catch((e) => {
              console.log(e)   
            })    
        } 

        Vue.prototype.$userContextfetchAssets = function(emitChange = true) {
            store
            .dispatch('app-aloha/fetchAssets', {
            // q: searchQuery.value,
              q: this.$userContext.queryFilter,
              uoList:this.$userContext.uoList,
              //worksite:this.$userContext.worksite,
              /*perPage: perPage.value,
              page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
              status: statusFilter.value,*/
            })
            .then(response => {
              const { assets, total } = response.data
              this.$userContextSetAssetList(emitChange, assets)
            })
            .catch((e) => {
              console.log(e)   
            })   
      }        



        Vue.prototype.$userContextInitialize = function(uoId) {
            this.$userContext.uoId = uoId
            this.$userContext.filters = {wks:[],assets:[],types:[],queries:[],iqoas:[]}
            this.$userContextfetchUo(true)

        }
       

    }
  };
  
  export default userContext;