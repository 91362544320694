export default [
 // *===============================================---*
  // *--------- PATRIMOINE  ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/patrimoine/carte',
    name: 'apps-patrimoine-carte',
    component: () => import('@/views/apps/patrimoine/patrimoine-carte/PatrimoineCarte.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Ouvrages',
      resource: 'patrimoine',
      action: 'read',  
      breadcrumb: [
        {
          text: 'Patrimoine',
        },
        {
          text: 'Carte',
          active: true,
        },
      ],        
    },
  },  
  {
    path: '/apps/patrimoine/fiche/:id',
    name: 'apps-patrimoine-fiche',
    component: () => import('@/views/apps/patrimoine/patrimoine-fiche/PatrimoineFiche.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Dossier d\'ouvrage',
      resource: 'patrimoine',
      action: 'read',  
      breadcrumb: [
        {
          text: 'Patrimoine',
        },
        {
          text: 'Dossier',
          active: true,
        },
      ],         
    },
  },  
  {
    path: '/apps/patrimoine/carnet/:id',
    name: 'apps-patrimoine-carnet',
    component: () => import('@/views/apps/patrimoine/patrimoine-carnet/PatrimoineCarnet.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Carnet de santé',
      resource: 'patrimoine',
      action: 'read',  
      breadcrumb: [
        {
          text: 'Patrimoine',
        },
        {
          text: 'Carnet',
          active: true,
        },
      ],         
    },
  }, 

  
 // *===============================================---*
  // *--------- INSPECTION  ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/inspection',
    name: 'apps-inspection',
    component: () => import('@/views/apps/inspection/Inspection.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Inspections',
      resource: 'patrimoine',
      action: 'read',  
      breadcrumb: [
        {
          text: 'Inspection',
        },
      ],       
    },
  },
  


  {
    path: '/apps/inspection/inspect/:id',
    name: 'apps-inspection-inspect',
    component: () => import('@/views/apps/inspection/inspection-inspect/InspectionInspect.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Réalisation d\'une inspection',    
      resource: 'inspect',
      action: 'read',   
    },
  },  
  
  {
    path: '/apps/inspection/add',
    name: 'apps-inspection-add',
    component: () => import('@/views/apps/inspection/inspection-add/InspectionAdd.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Création d\'une nouvelle inspection',   
      resource: 'patrimoine',
      action: 'read',   
    },
  },


 // *===============================================---*
  // *--------- MAINTENANCE  ---------------------------------------*
  // *===============================================---*
 /* {
    path: '/apps/maintenance/planning',
    name: 'apps-maintenance-planning',
    component: () => import('@/views/apps/maintenance/Maintenance.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Planning des maintenances',
      breadcrumb: [
        {
          text: 'Maintenance',
        },
        {
          text: 'Planning',
          active: true,
        },
      ],       
    },
  },*/


  {
    path: '/apps/maintenance',
    name: 'apps-maintenance-list',
    component: () => import('@/views/apps/maintenance/Maintenance.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Maintenances',     
      resource: 'maintenance',
      action: 'read',  
      breadcrumb: [
        {
          text: 'Maintenances',
        },
      ],         
    },
  },  
  
  {
    path: '/apps/releve/releve/:id',
    name: 'apps-releve-releve',
    component: () => import('@/views/apps/releve/Releve.vue'),
    meta: {
      resource: 'releve',
      action: 'read',
      contentClass: 'ecommerce-application',
      pageTitle: 'Relevé graphique',   
      breadcrumb: [
        {
          text: 'Inspection',
        },
      ],           
    },
  }, 

  /*{
    path: '/apps/maintenance/edit',
    name: 'apps-maintenance-edit',
    component: () => import('@/views/apps/maintenance/add/MaintenanceAdd.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Action de maintenance',   
      breadcrumb: [
        {
          text: 'Maintenance',
        },
        {
          text: 'Modification',
          active: true,
        },
      ],           
    },
  },*/

  {
    path: '/apps/maintenance/desordre',
    name: 'apps-maintenance-desordre',
    component: () => import('@/views/apps/desordre/DesordreList.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Liste des désordres',   
      resource: 'maintenance',
      action: 'read',  
      breadcrumb: [
        {
          text: 'Maintenance',
        },
        {
          text: 'Désordres',
          active: true,
        },
      ],           
    },
  },

  {
    path: '/apps/gestion/budget',
    name: 'apps-gestion-budget',
    component: () => import('@/views/apps/gestion/budget/Budget.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Budget 2022',  
      resource: 'gestion',
      action: 'read',   
      breadcrumb: [
        {
          text: 'Gestion',
        },
        {
          text: 'Budget',
          active: true,
        },
      ],           
    },
  },

  {
    path: '/apps/gestion/pluriannuel',
    name: 'apps-gestion-pluriannuel',
    component: () => import('@/views/apps/gestion/pluriannuel/Pluriannuel.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Plan pluriannuel', 
      resource: 'gestion',
      action: 'read',   
      breadcrumb: [
        {
          text: 'Gestion',
        },
        {
          text: 'Pluriannuel',
          active: true,
        },
      ],           
    },
  },

  

  {
    path: '/apps/incident/incident',
    name: 'apps-incident-incident',
    component: () => import('@/views/apps/incident/IncidentList.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Liste des incidents remontés',  
      resource: 'patrimoine',
      action: 'read',  
      breadcrumb: [
        {
          text: 'Incidents',
        },
      ],           
    },
  }, 

]
