import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  maintenances: [
    {
      id: 1,
      nom:'Réparation fissure',
      description:'Réparation fissure sur corniche ouvrage GU156',
      pilote:'ADP',
      delai:'60',
      priorite:'Haute',
      coutprev:'10 000',
      coutreal:'13 650',
      duree:'10',
      ouvragel:'GU-156',
      composant:'Corniches',
      typeaction:'Réparation',
      statut:'Terminé',
      auteur:'ENT1',
      date:'12/11/2021',
      startdate:'2021/11/12',      
      cadre: 'ID du 15/06/2021',
      desordre:'DES-121',
      documents:''
    },
    {
      id: 2,
      nom:'Renforcement appui tablier',
      description:'Renforcement appui tablier sur Dispositifs de retenue ouvrage GU156',
      pilote:'ADP',
      delai:'60',
      priorite:'Moyenne',
      coutprev:'60 000',
      coutreal:'55 890',
      duree:'25',
      ouvragel:'GU-156',
      composant:'Dispositifs de retenue',
      typeaction:'Renforcement',
      statut:'Terminé',
      auteur:'ENT1',
      date:'25/12/2021',
      startdate:'2021/12/25',
      cadre: 'ID du 15/06/2021',
      desordre:'DES-122',
      documents:'' 
    },
    {
      id: 3,
      nom:'Suivi état chaussée QS421',
      description:'Diagnostic sur Chaussée de la voie portée ouvrage QS421',
      pilote:'ADP',
      delai:'60',
      priorite:'Faible',
      coutprev:'15 000',
      coutreal:'',
      duree:'25',
      ouvragel:'QS421',
      composant:'Chaussée de la voie portée',
      typeaction:'Diagnostic',
      statut:'A planifier',
      auteur:'ENT1',
      date:'',
      cadre: '',
      desordre:'',
      documents:'' 
    },
    {
      id: 4,      
      nom:'Suivi étanchéité QS421',
      description:'Diagnostic sur étanchéité ouvrage QS421',
      pilote:'ADP',
      delai:'20',
      priorite:'Haute',
      coutprev:'15 000',
      coutreal:'',
      duree:'25',
      ouvragel:'QS421',
      composant:'Etanchéité',
      typeaction:'Surveillance',
      statut:'A planifier',
      auteur:'ENT1',
      date:'',
      cadre: '',
      desordre:'',
      documents:'' 
    },
    {
      id: 5,      
      nom:'Maintenance préventive étanchéité sur ouvrage PT561',
      description:'Maintenance préventive étanchéité sur ouvrage PT561',
      pilote:'ADP',
      delai:'45',
      priorite:'Haute',
      coutprev:'20 000',
      coutreal:'',
      duree:'10',
      ouvragel:'PT561',
      composant:'Etanchéité',
      typeaction:'Réparation',
      statut:'Planifié',
      auteur:'ENT1',
      date:'02/03/2022',
      startdate:'2022/03/02',
      cadre: 'Maintenance préventive',
      desordre:'',
      documents:''
    },
    {
      id: 6,
      nom:'Consolidation accotement voie portée ouvrage GH784',
      description:'Consolidation accotement voie portée ouvrage GH784',
      pilote:'ADP',
      delai:'60',
      priorite:'Moyenne',
      coutprev:'60 000',
      coutreal:'',
      duree:'25',
      ouvragel:'GH784',
      composant:'Trottoirs, bordures et accotements',
      typeaction:'Renforcement',
      statut:'En cours',
      auteur:'ENT1',
      date:'25/01/2022',
      startdate:'2022/01/25',
      cadre: 'ID du 15/06/2021',
      desordre:'DES-125',
      documents:'' 
    },
 
  ],

  typeAction: [
    {value:"Réparation",text:"Réparation",label:"Réparation"},
    {value:"Renforcement",text:"Renforcement",label:"Renforcement"},
    {value:"Surveillance",text:"Surveillance",label:"Surveillance"},
    {value:"Diagnostic",text:"Diagnostic",label:"Diagnostic"},
  ],

  statutAction: [
    {value:"A planifier",text:"A planifier"},
    {value:"Planifié",text:"Planifié"},
    {value:"En cours",text:"En cours"},
    {value:"Terminé",text:"Terminé"},
  ],
  
  
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/apps/maintenance/maintenances').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = {},
    perPage = 10,
    page = 1,
    sortBy = 'id',
    sortDesc = false,
  } = config.params
  /* eslint-enable */

  let filteredData = data.maintenances

  let typeaction = []      

  q.type.forEach(a => typeaction.push(a))
  if (typeaction.length > 0)  {
      filteredData = filteredData.filter(
      maintenance => typeaction.indexOf(maintenance.typeaction) !== -1 
    )
  }

  let priorite = []      

  q.priorite.forEach(a => priorite.push(a))
  if (priorite.length > 0)  {
      filteredData = filteredData.filter(
      maintenance => priorite.indexOf(maintenance.priorite) !== -1 
    )
  }

  let asset = []      

  q.assets.forEach(a => asset.push(a))
  if (asset.length > 0)  {
      filteredData = filteredData.filter(
      maintenance => asset.indexOf(maintenance.ouvragel) !== -1 
    )
  }  

  let status = []      

  q.status.forEach(a => status.push(a))
  if (status.length > 0)  {
      filteredData = filteredData.filter(
      maintenance => status.indexOf(maintenance.statut) !== -1 
    )
  }     

  var queryLowered = ''  

  if (q.queries.length > 0) {
     queryLowered = q.queries[0].toLowerCase()
     filteredData = filteredData.filter(
      maintenance => (maintenance.nom.toLowerCase().includes(queryLowered) || maintenance.ouvragel.toLowerCase().includes(queryLowered))
    )
  }

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      maintenances: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})


// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/apps/maintenance/typeactions').reply(() => {
  // eslint-disable-next-line object-curly-newline

  return [
    200,
    {
      typeactions: data.typeAction,
    },
  ]
})

// ------------------------------------------------
// Patch: edit maintenance
// ------------------------------------------------
mock.onPatch('/apps/maintenance/maintenances').reply(config => {
  // Get event from post data
  let { maintenance } = JSON.parse(config.data)

  let index = data.maintenances.findIndex(x => x.id === maintenance.id)
 
  data.maintenances[index] = maintenance

  return [201, { maintenance }]

})


// ------------------------------------------------
// POST: Add new user
// ------------------------------------------------
mock.onPost('/apps/maintenance/maintenances').reply(config => {
  // Get event from post data

  const { maintenance } = JSON.parse(config.data)

  maintenance.id = data.maintenances.length + 1

  data.maintenances.push(maintenance)

  return [201, { maintenance }]
})

// ------------------------------------------------
// GET: Return Single User
// ------------------------------------------------
mock.onGet(/\/apps\/maintenance\/maintenances\/\d+/).reply(config => {
  // Get event id from URL
  let maintenanceId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  maintenanceId = Number(maintenanceId)

  const maintenanceIndex = data.maintenances.findIndex(e => e.id === maintenanceId)
  const maintenance = data.maintenances[maintenanceIndex]

  if (maintenance) return [200, maintenance]
  return [404]
})
