import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchOuvrages(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/patrimoine/ouvrages', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPatrimoine(ctx, patrimoine) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/patrimoine/ouvrages', patrimoine )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },    
  },
}
