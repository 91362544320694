import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  shapes: [],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/apps/inspection/shapes').reply(config => {

  return [
    200,
    {
        shapes: data.shapes,
    },
  ]
})

// ------------------------------------------------
// POST: Add new user
// ------------------------------------------------
mock.onPost('/apps/inspection/shapes').reply(config => {
  // Get event from post data
  const { shape } = JSON.parse(config.data)

  data.shapes.push(shape)

  return [201, { shape }]
})
