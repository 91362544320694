import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  congratulations: {
    name: 'John',
    saleToday: '48900',
  },
  statisticsItems: [
    {
      icon: 'ListIcon',
      color: 'light-primary',
      title: '0',
      subtitle: 'Tâches en retard',
      customClass: 'mb-2 mb-xl-0',
    },
    {
      icon: 'TrendingUpIcon',
      color: 'light-info',
      title: '15%',
      subtitle: 'Avancement budget',
      customClass: 'mb-2 mb-xl-0',
    },
    {
      icon: 'TriangleIcon',
      color: 'light-danger',
      title: '3',
      subtitle: 'Incidents à qualifier',
      customClass: 'mb-2 mb-sm-0',
    },
    {
      icon: 'FrownIcon',
      color: 'light-warning',
      title: '12',
      subtitle: 'Désordres en cours',
      customClass: '',
    },
  ],
  statisticsOrder: {
    series: [
      {
        name: '2020',
        data: [45, 85, 65, 45, 65],
      },
    ],
  },
  statisticsProfit: {
    series: [
      {
        data: [0, 20, 5, 30, 15, 45],
      },
    ],
  },
  earningsChart: {
    series: [53, 16, 31],
  },
  revenue: {
    years: ['2020', '2019', '2018'],
    price: '25,852',
    budget: '56,800',
    revenueReport: {
      series: [
        {
          name: 'Earning',
          data: [95, 177, 284, 256, 105, 63, 168, 218, 72],
        },
        {
          name: 'Expense',
          data: [-145, -80, -60, -180, -100, -60, -85, -75, -100],
        },
      ],
    },
    budgetChart: {
      series: [
        {
          data: [61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62],
        },
        {
          data: [20, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27],
        },
      ],
    },
  },
  timeline: {
    step1: {
      title: 'Lancement de l\'inspection INS158',
      subtitle: 'Inspection détaillée sur GU-156',
      duration: 'il y a 3 jours',
    },
    step2: {
      title: 'Déclaration d\'un nouvel incident',
      subtitle: 'Fissure constatée mur de souténement GU-156',
      duration: 'il y a 8 jours',
    },
    step3: {
      title: 'Validation rapport d\'inspection',
      subtitle: 'Rapport VE GU156 par Veritas',
      duration: 'il y a 10 jours',
    },
    step4: {
      title: 'Création d\'un nouveau désordre',
      duration: 'il y a 12 jours',
      subtitle: 'INS158 : Fissure sur corniche GU-156',
    },
    step5: {
        title: 'Fin maintenance MAIN154',
        duration: 'il y a 15 jours',
        subtitle: 'Consolidation corniche GU-156',
      },    
  },  
  inspectionTable: [
    {
        type: 'Inspection détaillé',
        libelle: 'GU-156',
        created: '03/02/2022',
        status: 'En attente'
    },
    {
      type: 'Inspection détaillé',
      libelle: 'GU-156',
      created: '03/02/2022',
      status: 'En cours'
    },
    {
      type: 'Inspection détaillé',
      libelle: 'GU-156',
      created: '03/02/2022',
      status: 'En rédaction'
    },
    {
      type: 'Inspection détaillé',
      libelle: 'GU-156',
      created: '03/02/2022',
      status: 'Terminée'
    },

],
  companyTable: [
    {
        type: 'Fissure',
        libelle: 'Fissure corniche GU-156',
        created: '12/12/2021',
        iqoa: '3U',
        s: 'S',
        maintenance: 'MAIN-192',
        color:'text-primary',
    },
    {
        type: 'Tassement',
        libelle: 'Tassement des tampons côté Ouest  GU-156',
        created: '12/12/2021',
        iqoa: '3U',
        s: '',        
        maintenance: 'A créer',
        color:'text-warning',
    },
    {
      type: 'Fissure',
      libelle: '1 fissure transversale sur la chaussée côté Ouest',
      created: '12/12/2021',
      iqoa: '3',
      s: 'S',        
      maintenance: 'MAIN-184',
      color:'text-primary',
  },
    {
        type: 'Eclat',
        libelle: '2 amorces d’éclats béton côté Sud élément 17 ',
        created: '10/10/2021',
        iqoa: '3',
        s: '',        
        maintenance: 'MAIN-178',
        color:'text-primary',
    },

    {
        type: 'Tassement',
        libelle: 'Tassement important, quelques végétaux et stagnation d\’eau vers les bordures côté Nord et côté Sud ',
        created: '05/06/2021',
        iqoa: '3',
        s: '',        
        maintenance: 'MAIN-115',
        color:'text-primary',
    },
    {
        type: 'Defaut',
        libelle: 'fixation absente sur chaque montant de la BN1 ',
        created: '12/12/2021',
        iqoa: '3',
        s: '',        
        maintenance: 'MAIN-163',
        color:'text-primary',
    },
    {
        type: 'Fissure',
        libelle: 'Fissure corniche GU-156',
        created: '12/12/2021',
        iqoa: '2E',
        s: '',        
        maintenance: 'MAIN-145',
        color:'text-primary',
    },
  ],
  meetup: {
    mediaData: [
      { avatar: 'CalendarIcon', title: 'Sat, May 25, 2020', subtitle: '10:AM to 6:PM' },
      { avatar: 'MapPinIcon', title: 'Central Park', subtitle: 'Manhattan, New york City' },
    ],
    avatars: [
      { avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'), fullName: 'Billy Hopkins' },
      { avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'), fullName: 'Amy Carson' },
      { avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'), fullName: 'Brandon Miles' },
      { avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'), fullName: 'Daisy Weber' },
      { avatar: require('@/assets/images/portrait/small/avatar-s-20.jpg'), fullName: 'Jenny Looper' },
    ],
  },
  goalOverview: {
    completed: '786,617',
    inProgress: '13,561',
    series: [83],
  },
  transactionData: [
    {
      mode: 'Wallet',
      types: 'Starbucks',
      avatar: 'PocketIcon',
      avatarVariant: 'light-primary',
      payment: '-$74',
      deduction: true,
    },
    {
      mode: 'Bank Transfer',
      types: 'Add Money',
      avatar: 'CheckIcon',
      avatarVariant: 'light-success',
      payment: '+$480',
      deduction: false,
    },
    {
      mode: 'Paypal',
      types: 'Add Money',
      avatar: 'DollarSignIcon',
      avatarVariant: 'light-danger',
      payment: '+$480',
      deduction: false,
    },
    {
      mode: 'Mastercard',
      types: 'Ordered Food',
      avatar: 'CreditCardIcon',
      avatarVariant: 'light-warning',
      payment: '-$23',
      deduction: true,
    },
    {
      mode: 'Transfer',
      types: 'Refund',
      avatar: 'TrendingUpIcon',
      avatarVariant: 'light-info',
      payment: '+$98',
      deduction: false,
    },
  ],
}
/* eslint-disable global-require */
mock.onGet('/moa/data').reply(() => [200, data])
