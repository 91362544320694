export default 
    {
          worksite:{name:"Aménagement",icon:require('@/assets/images/ficheiqoa/worksite2.png'),scale:.15,type:"worksite"},
          tablier:{name:"Tablier pont-dalle en béton armé",type:"asset",icon:require('@/assets/images/ficheiqoa/Barrages.png'),scale:.08},
          tablierpont:{name:"Tablier pont-dalle en béton précontraint",type:"asset",icon:require('@/assets/images/ficheiqoa/Barrages.png'),scale:.08},
          tablierpontpoutre:{name:"Tablier pont à poutres sous chaussée en béton armé",type:"asset",icon:require('@/assets/images/ficheiqoa/Barrages.png'),scale:.08},
          cule:{name:"Appuis et appareils d’appui - Culées en béton armé",type:"asset",icon:require('@/assets/images/ficheiqoa/Barrages.png'),scale:.08},
          pile:{name:"Appuis et appareils d’appui - Pile en béton armé",type:"asset",icon:require('@/assets/images/ficheiqoa/Barrages.png'),scale:.08},    
          culemac:{name:"Appuis et appareils d’appui - Culées en maçonnerie",type:"asset",icon:require('@/assets/images/ficheiqoa/Barrages.png'),scale:.08},
          pilemac:{name:"Appuis et appareils d’appui - Pile en maçonnerie",type:"asset",icon:require('@/assets/images/ficheiqoa/Barrages.png'),scale:.08},
          pont:{name:"Pont en maçonnerie",type:"asset",icon:require('@/assets/images/ficheiqoa/Barrages.png'),scale:.08},
          pontela:{name:"Pont en maçonnerie avec élargissement",type:"datalogger",icon:require('@/assets/images/ficheiqoa/Barrages.png'),scale:.08},
          pontcad:{name:"Pont cadre, portique en béton",type:"datalogger",icon:require('@/assets/images/ficheiqoa/Barrages.png'),scale:.08},
          buse:{name:"Buse métallique",type:"datalogger",icon:require('@/assets/images/ficheiqoa/Barrages.png'),scale:.08},  
          busebe:{name:"Buse en béton armé",type:"datalogger",icon:require('@/assets/images/ficheiqoa/Barrages.png'),scale:.08},
          tablierpout:{name:"Tablier pont à poutrelles enrobées",type:"datalogger",icon:require('@/assets/images/ficheiqoa/Barrages.png'),scale:.08},
          tablierpoutpre:{name:"Tablier pont à poutres précontraintes par adhérence (PRAD)",type:"datalogger",icon:require('@/assets/images/ficheiqoa/Barrages.png'),scale:.08},
          tablierpontdalle:{name:"Tablier pont dalle nervurée ou pont à nervures en béton précontraint",type:"datalogger",icon:require('@/assets/images/ficheiqoa/Barrages.png'),scale:.08},
          mur:{name:"Mur poids en maçonnerie",type:"datalogger",icon:require('@/assets/images/ficheiqoa/Barrages.png'),scale:.08},                                   
          murbe:{name:"Mur poids en béton",type:"datalogger",icon:require('@/assets/images/ficheiqoa/Barrages.png'),scale:.08},  
          murga:{name:"Mur poids en gabions",type:"datalogger",icon:require('@/assets/images/ficheiqoa/Barrages.png'),scale:.08},
          murpre:{name:"Mur poids en éléments préfabriqués empilés en béton",type:"datalogger",icon:require('@/assets/images/ficheiqoa/Barrages.png'),scale:.08},
          muren:{name:"Mur en béton armé encastré sur semelle",type:"datalogger",icon:require('@/assets/images/ficheiqoa/Barrages.png'),scale:.08},
}
    