import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  incidents: [
    {
        id: 1,
        libelle:'Eclat béton sur corniche GU156',
        ouvrage:'GU156',
        description:'2 amorces d’éclats béton côté Sud élément 17',
        status:"Terminé",
        author:"Jean Dupond",
        created: '15/12/2021',
        updated: '30/01/2022',
        documents: [require('@/assets/images/pages/incident/image4.jpeg')],
        historique: ['15/12/2021 - Jean Dupond - Création de l\'incident','30/01/2022 - John Doe - Cloture de l\'incident'],
      },
      {
        id: 2,
        libelle:'Fissure appui RM027',
        ouvrage:'RM027',
        description:'fissure pied appui sur RM027 coté sud',
        status:"Terminé",
        author:"John Doe",
        created: '12/11/2021',
        updated: '30/06/2018',
        documents: [require('@/assets/images/pages/incident/image3.jpeg')],
        historique: ['12/11/2021 - John Doe - Création de l\'incident','30/01/2022 - John Doe - Cloture de l\'incident'],
     },      
    {
      id: 3,
      libelle:'Eclat béton sur corniche GU156',
      ouvrage:'GU156',
      description:'2 amorces d’éclats béton côté Sud élément 17',
      status:"A traiter",
      author:"Jean Dupond",
      created: '30/01/2022',
      updated: '30/06/2018',
      documents: [require('@/assets/images/pages/incident/image4.jpeg')],
      historique: ['30/01/2022 - Jean Dupond - Création de l\'incident'],
    },
    {
        id: 4,
        libelle:'Fissure appui RM027',
        ouvrage:'RM027',
        description:'fissure pied appui sur RM027 coté sud',
        status:"A traiter",
        author:"John Doe",
        created: '03/02/2022',
        updated: '30/06/2018',
        documents: [require('@/assets/images/pages/incident/image3.jpeg')],
        historique: ['03/02/2022 - John Doe - Création de l\'incident'],
      },
    {
        id: 5,
        libelle:'Nid de poule sur voie portée',
        ouvrage:'Pont 54',
        description:'Nid de poule sur voie portée entrée côté nord',
        status:"A traiter",
        author:"John Doe",
        created: '03/02/2022',
        updated: '30/06/2018',
        documents: [require('@/assets/images/pages/incident/image1.jpeg'),require('@/assets/images/pages/incident/image2.jpeg')],
        historique: ['03/02/2022 - John Doe - Création de l\'incident'],
      },

  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/apps/incident/incidents').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
    perPage = 10,
    page = 1,
    sortBy = 'id',
    sortDesc = false,
    status = null,
  } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.incidents.filter(
    incident =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (incident.libelle.toLowerCase().includes(queryLowered)) &&
      incident.status === (status || incident.status),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
        incidents: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// POST: Add new user
// ------------------------------------------------
mock.onPost('/apps/incident/incidents').reply(config => {
  // Get event from post data
  let { incident } = JSON.parse(config.data)

  incident.id =  data.incidents.length + 1

  data.incidents.push(incident)

  return [201, { incident }]

})

// ------------------------------------------------
// POST: Add new user
// ------------------------------------------------
mock.onPatch('/apps/incident/incidents').reply(config => {
  // Get event from post data
  let { incident } = JSON.parse(config.data)

  let index = data.incidents.findIndex(x => x.id === incident.id)
 
  data.incidents[index] = incident

  return [201, { incident }]

})

// ------------------------------------------------
// GET: Return Single User
// ------------------------------------------------
mock.onGet(/\/apps\/incident\/incidents\/\d+/).reply(config => {
  // Get event id from URL
  let incidentId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  incidentId = Number(incidentId)

  const incidentIndex = data.incidents.findIndex(e => e.id === incidentId)
  const incident = data.incidents[incidentIndex]

  if (incident) return [200, incident]
  return [404]
})
