import Vue from 'vue'
import Vuex from 'vuex'

// Modules
//import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import alohaStoreModule from '@/api/alohaStoreModule'
import patrimoineStoreModule from '@/views/apps/patrimoine/patrimoine-carte/patrimoineStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    //'app-ecommerce': ecommerceStoreModule,
    'app-patrimoine': patrimoineStoreModule,
    'app-aloha': alohaStoreModule,
  },
  strict: process.env.DEV,
})
