import mock from '@/@fake-db/mock'
// eslint-disable-next-line object-curly-newline
import { paginateArray, sortCompare, randomDate, getRandomInt } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {   
  uos: [
    {
      id: 1,
      name: 'Paris Charles De Gaulle - Roissy',
      parentid: 1,
      center: [2.3524475097656254,48.85994726542142],
      zoomlevel:11,     
    },

    {
        id: 2,
        name: 'Paris - Orly',
        parentid: 1,
        center: [2.3678970336914067,48.72708142926293],
        zoomlevel:10,     
    },
    {
      id: 3,
      name: 'Paris - Le Bourget',
      parentid: 1,
      center: [2.438278198242188,48.95687584173926],
      zoomlevel:10,     
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return products
// ------------------------------------------------
mock.onGet('/apps/patrimoine/uos').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', parentid='', type='', perPage = 100, page = 1 } = config.params

  const queryLowered = q.toLowerCase()

  let filteredData = data.uos.filter(uo => uo.name.toLowerCase().includes(queryLowered))

  if (parentid != '')  {
    filteredData = filteredData.filter(uo => (uo.parentid == parentid || uo.id == parentid)) 
  } 



  const paginatedData = JSON.parse(JSON.stringify(paginateArray(filteredData, perPage, page)))
 
  return [
    200,
    {
      uos: paginatedData,
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// GET: Return Single User
// ------------------------------------------------
mock.onGet(/\/apps\/patrimoine\/uos\/\d+/).reply(config => {
  // Get event id from URL
  let uoId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  uoId = Number(uoId)

  const uoIndex = data.uos.findIndex(e => e.id === uoId)
  const uo = data.uos[uoIndex]

  if (uo) return [200, uo]
  return [404]
})

// ------------------------------------------------
// POST: Add new user
// ------------------------------------------------
mock.onPost('/apps/patrimoine/uos').reply(config => {
  // Get event from post data
  let uo = JSON.parse(config.data)

  uo.id = data.uos.length + 1
 
  data.uos.push(uo)

  return [201, { uo }]
})


